import { MainLayout } from "./layouts/MainLayout";

function App() {

  return (
    <MainLayout />
  );
}

export default App;
