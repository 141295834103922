export function Footer() {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                                <h2 className="footer-heading mb-4">Quick Links</h2>
                                <ul className="list-unstyled">
                                    <li><a href="http://localhost:3000">About Us</a></li>
                                    <li><a href="http://localhost:3000">Services</a></li>
                                    <li><a href="http://localhost:3000">Testimonials</a></li>
                                    <li><a href="http://localhost:3000">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                                <h2 className="footer-heading mb-4">Products</h2>
                                <ul className="list-unstyled">
                                    <li><a href="http://localhost:3000">About Us</a></li>
                                    <li><a href="http://localhost:3000">Services</a></li>
                                    <li><a href="http://localhost:3000">Testimonials</a></li>
                                    <li><a href="http://localhost:3000">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                                <h2 className="footer-heading mb-4">Features</h2>
                                <ul className="list-unstyled">
                                    <li><a href="http://localhost:3000">About Us</a></li>
                                    <li><a href="http://localhost:3000">Services</a></li>
                                    <li><a href="http://localhost:3000">Testimonials</a></li>
                                    <li><a href="http://localhost:3000">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                                <h2 className="footer-heading mb-4">Follow Us</h2>
                                <a href="http://localhost:3000" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
                                <a href="http://localhost:3000" className="pl-3 pr-3"><span className="icon-twitter"></span></a>
                                <a href="http://localhost:3000" className="pl-3 pr-3"><span className="icon-instagram"></span></a>
                                <a href="http://localhost:3000" className="pl-3 pr-3"><span className="icon-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <h2 className="footer-heading mb-4">Subscribe Newsletter</h2>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <form action="#" method="post" className="subscription">
                            <div className="input-group mb-3  d-flex align-items-stretch">
                                <input type="text" className="form-control bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2" />
                                <button className="btn btn-primary text-white" type="button" id="button-addon2">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row pt-5 mt-5">
                    <div className="col-12 text-md-center text-left">
                        <p>
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart text-danger" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank" >Colorlib</a>
                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}